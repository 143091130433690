export default {
  menuList: [
    { title: "首页", pathName: "HomePage" },
    {
      title: "关于展会",
      pathName: "AboustExhibition",
      children: [
        {
          title: "展会介绍",
          pathName: "ExhibitionIntroduction",
        },
        {
          title: "组织机构",
          pathName: "ExhibitionOrganization",
        },
        {
          title: "为何参展",
          pathName: "WhyJoin",
        },
        {
          title: "展会规模",
          pathName: "SecondExhibitionScale",
        },
      ],
    },
    {
      title: "展商中心",
      pathName: "ExhibitorCenter",
      children: [
        {
          title: "展区规划",
          pathName: "CenterPlan",
        },
        {
          title: "参展费用",
          pathName: "CenterMoney",
        },
        {
          title: "参展申请",
          pathName: "CenterRequest",
        },
        {
          title: "展商手册",
          pathName: "CenterManual",
        },
        {
          title: "展商推介",
          pathName: "CenterRecommend",
        },
        {
          title: "资料下载",
          pathName: "CenterDownload",
        },
      ],
    },
    {
      title: "观众中心",
      pathName: "Audience",
      children: [
        {
          title: "观众群体",
          pathName: "AudiencePeople",
        },
        {
          title: "参观登记",
          pathName: "AudienceRegister",
        },
        {
          title: "参观指南",
          pathName: "AudienceFingerpost",
        },
      ],
    },
    {
      title: "同期活动",
      pathName: "Activity",
      children: [
        {
          title: "配套活动",
          pathName: "TCM",
        },
        {
          title: "会议论坛",
          pathName: "ConferenceForum",
        },
      ],
    },
    {
      title: "新闻中心",
      pathName: "NewsCenter",
      children: [
        {
          title: "行业新闻",
          pathName: "TradeNews",
        },
        {
          title: "展会新闻",
          pathName: "ExhibitionNews",
        },
        {
          title: "合作媒体",
          pathName: "MideaNews",
        },
      ],
    },
    {
      title: "联系我们",
      pathName: "CallUs",
    },
  ],
  activeMenu: 0,
  activeMenuTWO: 0,
};

import Vue from "vue";
import VueRouter from "vue-router";

const originalPush = VueRouter.prototype.push;

VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "HomeView",
    component: () => import("@/views/HomeView"),
    meta: {
      title: "首页",
    },
    redirect: "HomePage",
    children: [
      {
        path: "HomePage",
        name: "HomePage",
        component: () => import("@/views/HomePage"),
        meta: {
          title: "首页",
        },
      },
      {
        path: "AboustExhibition",
        name: "AboustExhibition",
        component: () => import("@/views/SecondPage/AboustExhibition"),
        redirect: { name: "ExhibitionIntroduction" },
        meta: {
          title: "关于展会",
        },
        children: [
          {
            path: "ExhibitionIntroduction",
            name: "ExhibitionIntroduction",
            component: () =>
              import(
                "@/views/SecondPage/AboustExhibition/ExhibitionIntroduction"
              ),
            meta: {
              title: "展会介绍",
            },
          },
          {
            path: "ExhibitionOrganization",
            name: "ExhibitionOrganization",
            component: () =>
              import(
                "@/views/SecondPage/AboustExhibition/ExhibitionOrganization"
              ),
            meta: {
              title: "组织机构",
            },
          },
          {
            path: "WhyJoin",
            name: "WhyJoin",
            component: () =>
              import("@/views/SecondPage/AboustExhibition/WhyJoin"),
            meta: {
              title: "为何参展",
            },
          },
          {
            path: "SecondExhibitionScale",
            name: "SecondExhibitionScale",
            component: () =>
              import(
                "@/views/SecondPage/AboustExhibition/SecondExhibitionScale"
              ),
            meta: {
              title: "展会规模",
            },
          },
        ],
      },
      {
        path: "ExhibitorCenter",
        name: "ExhibitorCenter",
        component: () => import("@/views/SecondPage/ExhibitorCenter"),
        meta: {
          title: "展商中心",
        },
        redirect: { name: "CenterPlan" },
        children: [
          {
            path: "CenterPlan",
            name: "CenterPlan",
            component: () =>
              import("@/views/SecondPage/ExhibitorCenter/CenterPlan"),
            meta: {
              title: "展区规划",
            },
          },
          {
            path: "CenterMoney",
            name: "CenterMoney",
            component: () =>
              import("@/views/SecondPage/ExhibitorCenter/CenterMoney"),
            meta: {
              title: "参展费用",
            },
          },
          {
            path: "CenterRequest",
            name: "CenterRequest",
            component: () =>
              import("@/views/SecondPage/ExhibitorCenter/CenterRequest"),
            meta: {
              title: "参展申请",
            },
          },
          {
            path: "CenterManual",
            name: "CenterManual",
            component: () =>
              import("@/views/SecondPage/ExhibitorCenter/CenterManual"),
            meta: {
              title: "展商手册",
            },
          },
          {
            path: "CenterRecommend",
            name: "CenterRecommend",
            component: () =>
              import("@/views/SecondPage/ExhibitorCenter/CenterRecommend"),
            meta: {
              title: "展商推介",
            },
          },
          {
            path: "CenterDownload",
            name: "CenterDownload",
            component: () =>
              import("@/views/SecondPage/ExhibitorCenter/CenterDownload"),
            meta: {
              title: "资料下载",
            },
          },
        ],
      },
      {
        path: "Audience",
        name: "Audience",
        component: () => import("@/views/SecondPage/Audience"),
        redirect: { name: "AudiencePeople" },
        meta: {
          title: "观众中心",
        },
        children: [
          {
            path: "AudiencePeople",
            name: "AudiencePeople",
            component: () =>
              import("@/views/SecondPage/Audience/AudiencePeople"),
            meta: {
              title: "观众群体",
            },
          },
          {
            path: "AudienceFingerpost",
            name: "AudienceFingerpost",
            component: () =>
              import("@/views/SecondPage/Audience/AudienceFingerpost"),
            meta: {
              title: "参观指南",
            },
          },
          {
            path: "AudienceRegister",
            name: "AudienceRegister",
            component: () =>
              import("@/views/SecondPage/Audience/AudienceRegister"),
            meta: {
              title: "参观登记",
            },
          },
        ],
      },
      {
        path: "NewsCenter",
        name: "NewsCenter",
        component: () => import("@/views/SecondPage/NewsCenter"),
        redirect: { name: "TradeNews" },
        meta: {
          title: "新闻中心",
        },
        children: [
          {
            path: "TradeNews",
            name: "TradeNews",
            component: () => import("@/views/SecondPage/NewsCenter/TradeNews"),
            meta: {
              title: "行业新闻",
            },
          },
          {
            path: "ExhibitionNews",
            name: "ExhibitionNews",
            component: () =>
              import("@/views/SecondPage/NewsCenter/ExhibitionNews"),
            meta: {
              title: "展会新闻",
            },
          },
          {
            path: "MideaNews",
            name: "MideaNews",
            component: () => import("@/views/SecondPage/NewsCenter/MideaNews"),
            meta: {
              title: "合作媒体",
            },
          },
        ],
      },
      {
        path: "Activity",
        name: "Activity",
        component: () => import("@/views/SecondPage/Activity"),
        redirect: { name: "ConferenceForum" },
        meta: {
          title: "同期活动",
        },
        children: [
          {
            path: "TCM",
            name: "TCM",
            component: () => import("@/views/SecondPage/Activity/TCM"),
            meta: {
              title: "配套活动",
            },
          },
          {
            path: "ConferenceForum",
            name: "ConferenceForum",
            component: () =>
              import("@/views/SecondPage/Activity/ConferenceForum"),
            meta: {
              title: "会议论坛",
            },
          },
        ],
      },
      {
        path: "CallUs",
        name: "CallUs",
        component: () => import("@/views/SecondPage/CallUs"),
        meta: {
          title: "联系我们",
        },
      },
      {
        path: "CommonDetail",
        name: "CommonDetail",
        component: () => import("@/components/Common/CommonDetail"),
        meta: {
          title: "详情",
        },
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 };
  },
});

export default router;

<template>
  <div class="common-exhibition">
    <div v-if="isShowTitle" class="common-exhibition-title">{{ title }}</div>
    <div class="common-exhibition-content" v-html="info.content"></div>
  </div>
</template>

<script>
export default {
  name: "CommonExhibition",

  props: {
    title: {
      type: String,
      default: "展会介绍",
    },
    isShowTitle: {
      type: Boolean,
      default: true,
    },
  },

  created() {
    this.getInformation(this.title);
  },
};
</script>
<style scoped lang="scss">
.common-exhibition {
  .common-exhibition-title {
    font-size: 32px;
    color: #292932;
    text-align: center;
    font-weight: bold;
    margin-bottom: 16px;
  }
}
</style>

import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "@/assets/styles/reset.css";
import http from "@/utils/http";
import "@/utils/rem";
import {
  Carousel,
  CarouselItem,
  Icon,
  Message,
  Form,
  FormItem,
  Row,
  Col,
  Input,
  Checkbox,
  CheckboxGroup,
  Button,
  Pagination,
  Popover,
} from "element-ui";
import CommonTitle from "@/components/Common/CommonTitle";
import CommonTitleTwo from "@/components/Common/CommonTitleTwo";
import CommonBread from "@/components/Common/CommonBread";
import CommonSideBar from "@/components/Common/CommonSideBar";
import CommonExhibition from "@/components/Common/CommonExhibition";
import Mixin from "@/mixins";

import { apiUrl } from "@/utils/request";

Vue.use(Carousel);
Vue.use(Icon);
Vue.use(CarouselItem);
Vue.use(Form);
Vue.use(FormItem);
Vue.use(Row);
Vue.use(Col);
Vue.use(Input);
Vue.use(Checkbox);
Vue.use(CheckboxGroup);
Vue.use(Button);
Vue.use(Pagination);
Vue.use(Popover);

Vue.mixin(Mixin);

Vue.component(CommonTitleTwo.name, CommonTitleTwo);
Vue.component(CommonTitle.name, CommonTitle);
Vue.component(CommonBread.name, CommonBread);
Vue.component(CommonSideBar.name, CommonSideBar);
Vue.component(CommonExhibition.name, CommonExhibition);

Vue.prototype.$IMGURL =
  process.env.NODE_ENV === "development"
    ? `${apiUrl}sys/common/static`
    : `${apiUrl}sys/common/static`;
Vue.prototype.$message = Message;

Vue.config.productionTip = false;

Vue.prototype.$http = http;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");

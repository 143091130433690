<template>
  <div id="app">
    <router-view></router-view>
    <div
      class="sider-fixed"
      :style="{
        transform: 'translateY(-40%)',
        top: '50%',
        position: 'fixed',
      }"
    >
      <div
        :class="['fixed-item', index == 0 && 'has-radius']"
        v-for="(item, index) in fixedList"
        :key="item.id"
        @click="goToDetail(item)"
      >
        <div class="fixed-img">
          <img :src="`${$IMGURL}${item.iconAddress}`" alt="" />
        </div>
        <div class="fixed-text" :title="item.title">{{ item.title }}</div>
        <div class="code" v-if="item.type == '二维码'">
          <img :src="`${$IMGURL}${item.orCodeAddress}`" alt="" />
        </div>
      </div>
      <div class="fixed-item bottom-radius" @click="returnTop">
        <div class="fixed-img">
          <img src="@/assets/images/Home/top.png" alt="" />
        </div>
        <div class="fixed-text">TOP</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "App",
  data() {
    return {
      fixedList: [],
      scrollTop: 0,
    };
  },

  created() {
    this.getNavigationBar();
  },

  mounted() {
    window.addEventListener("scroll", this.handleScroll); // 监听页面滚动
  },
  methods: {
    // 获取页面滚动距离
    handleScroll() {
      this.scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
    },
    async getNavigationBar() {
      const res = await this.$http.get("frontDesk/homePage/getNavigationBar");
      if (!res.success) return this.$message.error(res.message);
      this.fixedList = res.result;
    },

    returnTop() {
      window.scrollTo({ top: 0, behavior: "smooth" });
    },

    goToDetail(item) {
      if (item.type !== "二维码") {
        window.open(item.url);
      }
    },
  },
};
</script>

<style lang="scss">
#app {
  // font-family: Source Han Sans CN-Medium, Source Han Sans CN;
  font-family: $PromaryFontFamily;
  overflow: hidden;
  .sider-fixed {
    // position: fixed;
    // top: 898px;
    right: 0;
    // transform: translateY(-50%);
    border-radius: 8px 0px 0px 8px;
    z-index: 9999;
    .fixed-item {
      width: 92px;
      height: 92px;
      background-color: $primaryColor;
      @include flex-center;
      flex-direction: column;
      cursor: pointer;
      position: relative;

      &.has-radius {
        border-radius: 8px 0 0 0;
      }

      &.bottom-radius {
        border-radius: 0 0 0 8px;
      }

      &:hover .code {
        display: block;
      }

      .code {
        width: 140px;
        height: 140px;
        border-radius: 8px;
        background-color: #fff;
        display: none;
        position: absolute;
        left: -150px;
        padding: 10px;

        &::after {
          position: absolute;
          content: "";
          width: 0;
          height: 0;
          right: -14px;
          top: 50%;
          transform: translateY(-50%);
          border: 8px solid transparent;
          border-left-color: #fff;
        }

        img {
          width: 100%;
          height: 100%;
        }
      }

      .fixed-img {
        width: 44px;
        min-height: 44px;
        padding: 8px 2px 6px 3px;

        img {
          width: 100%;
          // height: 100%;
        }
      }
      .fixed-text {
        @include textEllipsis;
        font-size: 16px;
        color: #ffffff;
        // margin-top: 10px;
        width: 100%;
        text-align: center;
      }
    }
  }
}
.sider-fixed {
}
</style>

<template>
  <div class="common-bread-container">
    <div class="common-banner">
      <img :src="bacImg" alt="" />
    </div>
    <div class="banner-title">{{ title }}</div>
    <div class="common-bread">
      <div class="common-bread-wrapper">
        <img
          class="el-icon-s-home"
          src="@/assets/images/Home/home-bread.png"
          alt=""
        />
        <span
          v-for="(item, index) in breadList"
          :key="item.pathName"
          class="bread-item"
        >
          <span class="bread-title" @click="goToDetail(item.pathName)">{{
            item.title
          }}</span>
          <i
            v-if="index !== breadList.length - 1"
            class="el-icon-arrow-right"
          ></i>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CommonBread",
  props: {
    title: {
      type: String,
      default: "关于展会",
    },
    bacImg: {
      type: String,
      default: require("@/assets/images/Home/AboustExhibition.png"),
    },
  },

  data() {
    return {
      detailList: [],
    };
  },

  created() {
    if (this.$route.query.breadList && this.$route.query.breadList.length > 0) {
      this.detailList = JSON.parse(this.$route.query.breadList);
    }
  },

  methods: {
    goToDetail(name) {
      this.$router.push({ name });
    },
  },

  computed: {
    breadList() {
      const breadList = [];
      this.$route.matched.map((item) => {
        breadList.push({
          pathName: item.name,
          title: item.meta.title,
        });
      });
      if (this.detailList && this.detailList.length > 0) {
        return this.detailList;
      } else {
        return breadList;
      }
    },
  },
};
</script>
<style scoped lang="scss">
.common-bread-container {
  position: relative;

  .common-banner {
    width: 100%;
    height: 200px;
    background-color: #ccc;
  }

  .common-bread {
    width: 100%;
    position: absolute;
    bottom: 0;
    background: rgba(0, 0, 0, 0.3);
    height: 50px;

    .common-bread-wrapper {
      @include commonCenter;
      @include flex-vh-center;
      height: 100%;
      color: #fff;

      .bread-item {
        .el-icon-s-home {
          margin-right: 4px;
        }

        .el-icon-arrow-right {
          margin: 0 6px;
        }

        .bread-title {
          font-size: 18px;
          color: #fff;
          cursor: pointer;
        }
      }
    }
  }

  .banner-title {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    font-size: 64px;
    color: #d99b24;
    // font-family: HYShangWeiShouShuW-Regular, HYShangWeiShouShuW;
    font-family: $PromaryFontFamily;

    font-weight: bold;
  }
}
</style>

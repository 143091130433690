<template>
  <div class="common-title">
    <div class="img">
      <img :src="bacImg" alt="" />
    </div>
    <div class="title">{{ title }}</div>
  </div>
</template>

<script>
export default {
  name: "CommonTitle",

  props: {
    title: {
      type: String,
      default: "展会介绍",
    },
    bacImg: {
      type: String,
      default: require("images/Home/ExhibitionProfile.png"),
    },
  },

  data() {
    return {};
  },

  created() {
    console.log(this.bacImg);
  },
};
</script>
<style scoped lang="scss">
.common-title {
  width: 100%;
  height: 116px;
  position: relative;

  &::after {
    content: "";
    position: absolute;
    width: 48px;
    height: 4px;
    background-color: $primaryColor;
    bottom: 0;
  }

  .img {
    position: absolute;
    top: 50%;
    left: -45px;
    transform: translateY(-50%);
  }

  .title {
    position: absolute;
    font-size: 56px;
    height: 84px;
    top: 14px;
    font-family: $PromaryFontFamily;
  }
}
</style>

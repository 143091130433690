export default {
  data() {
    return {
      info: {},
    };
  },
  watch: {
    $route: {
      immediate: true,
      deep: true,
      handler(nv, ov) {
        if (nv.matched.length <= 1) return;
        // 当前菜单项刷新不丢失
        const index = this.$store.state.menuList.findIndex(
          (item) => item.pathName == nv.matched[1].name
        );
        this.activeMenu = index;
        this.$store.commit("SET_ACTIVEMENU", index);
        if (nv.matched.length > 2) {
          const index2 = this.$store.state.menuList[index].children.findIndex(
            (item) => item.pathName == nv.matched[2].name
          );
          this.activeTwo = index2;
          this.$store.commit("SET_ACTIVEMENUTWO", index2);
        }
      },
    },
  },
  methods: {
    async getInformation(type) {
      const res = await this.$http.get("frontDesk/richText/getInformation", {
        type,
      });
      if (!res.success) return this.$message.error(res.message);
      this.info = res.result || {};
    },

    HTMLDecode(text) {
      var temp = document.createElement("div");
      temp.innerHTML = text;
      var output = temp.innerText || temp.textContent;
      temp = null;
      return output;
    },
  },
};

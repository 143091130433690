<template>
  <div
    class="common-title-two"
    :style="{ 'border-bottom': showbd ? '2px solid rgb(186 186 186)' : 'none' }"
  >
    <div class="img">
      <img
        src="../../assets/images/Home/logo.png"
        style="width: 100; height: 100%"
        alt=""
        srcset=""
      />
    </div>
    <div class="title">{{ title }}</div>
  </div>
</template>

<script>
export default {
  name: "CommonTitleTwo",

  props: {
    title: {
      type: String,
      default: "展会介绍",
    },
    showbd: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {};
  },

  created() {},
};
</script>
<style scoped lang="scss">
.common-title-two {
  width: 100%;
  height: 130px;
  padding: 37px 0 36px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  .img {
    width: 40px;
    height: 40px;
    margin-right: 24px;
  }
  .title {
    font-size: 32px;
    font-family: Source Han Sans CN-Medium, Source Han Sans CN;
    font-weight: 500;
    color: #44444f;
    line-height: 40px;
  }
}
</style>

<template>
  <div class="common-side-bar">
    <div class="side-bar-title">{{ title }}</div>
    <div class="side-bar-list">
      <div
        :class="['side-bar-item', active === index && 'active-side-bar']"
        v-for="(item, index) in sidebarList"
        :key="item.pathName"
        @click="goToDetail(index, item)"
      >
        {{ item.title }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CommonSideBar",

  props: {
    sidebarList: {
      type: Array,
      default: () => {
        return [];
      },
    },
    title: {
      type: String,
      default: "关于展会",
    },
  },

  data() {
    return {
      active: 0,
    };
  },

  created() {
    const index = this.sidebarList.findIndex(
      (item) => item.title === this.$route.meta.title
    );
    this.active = index;
  },

  methods: {
    goToDetail(index, item) {
      if (this.active === index) return;
      this.active = index;
      this.$router.push({
        name: item.pathName,
      });
    },
  },

  watch: {
    $route: {
      handler(nRoute) {
        const index = this.sidebarList.findIndex(
          (item) => item.title === nRoute.meta.title
        );
        this.active = index;
      },
    },
  },
};
</script>
<style scoped lang="scss">
.common-side-bar {
  padding: 8px 0;
  width: 260px;
  max-height: 742px;
  background-color: #fff;
  box-shadow: 0px 2px 10px 0px rgba(4, 18, 97, 0.05);
  border-radius: 8px;

  .side-bar-title {
    width: 100%;
    height: 48px;
    position: relative;
    font-size: 20px;
    color: #44444f;
    line-height: 48px;
    text-indent: 24px;
    font-weight: bold;

    &::before {
      content: "";
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      background-color: $primaryColor;
      width: 3px;
      height: 16px;
    }
  }

  .side-bar-list {
    .side-bar-item {
      height: 48px;
      text-indent: 24px;
      line-height: 48px;
      color: #696974;
      font-size: 16px;
      cursor: pointer;

      &.active-side-bar {
        background-color: $primaryColor;
        color: #fff;
      }
    }
  }
}
</style>
